.projects-container {
  min-height: 100vh;
  padding: 2rem;
  background-color: #0a192f;
  color: #8892b0;
}

.projects-content {
  max-width: 1200px;
  margin: 0 auto;
}

.projects-content h1 {
  color: #64ffda;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.projects-section {
  margin-bottom: 4rem;
}

.projects-section h2 {
  color: #ccd6f6;
  font-size: 1.8rem;
  margin-bottom: 2rem;
  border-bottom: 2px solid rgba(100, 255, 218, 0.1);
  padding-bottom: 0.5rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.project-card {
  background: rgba(10, 25, 47, 0.85);
  border-radius: 10px;
  padding: 1.5rem;
  border: 1px solid rgba(100, 255, 218, 0.1);
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
}

.project-card:hover {
  transform: translateY(-5px);
  border-color: #64ffda;
  box-shadow: 0 4px 20px rgba(100, 255, 218, 0.1);
}

.project-card h3 {
  color: #64ffda;
  font-size: 1.3rem;
  margin: 1rem 0;
}

.project-card p {
  color: #8892b0;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.project-icon {
  font-size: 2rem;
  color: #64ffda;
}

.project-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: #64ffda;
  text-decoration: none;
  font-size: 0.9rem;
  margin-top: 1rem;
  transition: all 0.3s ease;
}

.project-link:hover {
  color: #ccd6f6;
}

.professional {
  border-left: 4px solid #64ffda;
}

.personal {
  border-left: 4px solid #8892b0;
}

@media (max-width: 768px) {
  .projects-container {
    padding: 1rem;
  }

  .projects-grid {
    grid-template-columns: 1fr;
  }

  .project-card {
    padding: 1rem;
  }

  .projects-content h1 {
    font-size: 2rem;
  }

  .projects-section h2 {
    font-size: 1.5rem;
  }
}
