.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #0a192f;
}

.main-content {
  flex: 1;
  padding: 80px 20px 20px;
  position: relative;
  z-index: 2;
}

/* Ensure content doesn't overlap with fixed navbar */
@media (max-width: 768px) {
  .main-content {
    padding-top: 100px;
  }
}
