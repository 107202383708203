.about-container {
  min-height: 100vh;
  padding: 2rem;
  background-color: #0a192f;
  color: #8892b0;
}

.about-content {
  max-width: 1000px;
  margin: 0 auto;
  background: rgba(10, 25, 47, 0.85);
  padding: 2rem;
  border-radius: 15px;
  backdrop-filter: blur(10px);
}

.about-header {
  text-align: center;
  margin-bottom: 2rem;
}

.about-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
  border: 3px solid #64ffda;
}

.about-header h1 {
  color: #64ffda;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.about-text {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.education-section h2 {
  color: #64ffda;
  margin: 2rem 0 1rem;
}

.education-list {
  display: grid;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.education-item {
  padding: 1rem;
  border: 1px solid rgba(100, 255, 218, 0.1);
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.education-item:hover {
  transform: translateY(-5px);
  border-color: #64ffda;
}

.education-item h3 {
  color: #ccd6f6;
  margin-bottom: 0.5rem;
}

.location, .period {
  color: #64ffda;
  font-size: 0.9rem;
}

.courses-list {
  display: grid;
  gap: 1rem;
  margin-bottom: 2rem;
}

.course-item {
  padding: 0.8rem;
  border: 1px solid rgba(100, 255, 218, 0.1);
  border-radius: 8px;
  transition: all 0.3s ease;
}

.course-item:hover {
  border-color: #64ffda;
  background: rgba(100, 255, 218, 0.05);
}

.additional-note {
  font-style: italic;
  margin: 2rem 0;
  padding: 1rem;
  border-left: 3px solid #64ffda;
}

.contact-section {
  margin-top: 3rem;
}

.email {
  color: #64ffda;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.social-links {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.social-links a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #8892b0;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(100, 255, 218, 0.1);
  border-radius: 5px;
  transition: all 0.3s ease;
}

.social-links a:hover {
  color: #64ffda;
  border-color: #64ffda;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .about-container {
    padding: 1rem;
  }

  .about-content {
    padding: 1rem;
  }

  .about-image {
    width: 150px;
    height: 150px;
  }

  .social-links {
    justify-content: center;
  }
}
