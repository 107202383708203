.footer {
  background-color: rgba(10, 25, 47, 0.85);
  backdrop-filter: blur(10px);
  padding: 2rem 0;
  margin-top: auto;
  border-top: 1px solid rgba(100, 255, 218, 0.1);
  position: relative;
  z-index: 10;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.footer-section {
  margin-bottom: 1.5rem;
}

.footer-section h3 {
  color: #64ffda;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.social-links {
  display: flex;
  gap: 2rem;
}

.social-links a {
  color: #8892b0;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.social-links a:hover {
  color: #64ffda;
}

.social-links a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: #64ffda;
  transition: width 0.3s ease;
}

.social-links a:hover::after {
  width: 100%;
}

.icon {
  font-size: 1.2em;
}

.heart-icon {
  color: #64ffda;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.3rem;
  animation: pulse 1.5s ease infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.footer-bottom {
  text-align: center;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(100, 255, 218, 0.1);
  color: #8892b0;
}

@media (max-width: 768px) {
  .social-links {
    justify-content: center;
  }
  
  .footer-section h3 {
    text-align: center;
  }
}
