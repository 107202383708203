.home {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: #0a192f;
}

.hero {
  position: relative;
  text-align: center;
  padding: 6rem 2rem;
  z-index: 2;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
  background: rgba(10, 25, 47, 0.85);
  padding: 3rem;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 255, 0, 0.1);
}

.title-container {
  height: 80px; /* Adjust based on your needs */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.hero-title {
  font-size: 2.5rem;
  color: #64ffda;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin: 0;
  line-height: 1.2;
}

.type-animation {
  display: inline-block;
  min-height: 80px;
}

.subtitle {
  font-size: 1.8rem;
  color: #8892b0;
  margin-bottom: 3rem;
  line-height: 1.6;
}

.highlight {
  color: #64ffda;
  font-weight: 500;
  padding: 0 0.5rem;
  display: inline-block;
  cursor: pointer;
}

.cta-buttons {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.cta-button {
  padding: 1rem 2rem;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.primary {
  background: linear-gradient(45deg, #64ffda, #00b7ff);
  color: #0a192f;
  border: none;
  box-shadow: 0 4px 15px rgba(100, 255, 218, 0.3);
}

.secondary {
  background: transparent;
  border: 2px solid #64ffda;
  color: #64ffda;
}

.button-icon {
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.icon-inline {
  vertical-align: middle;
  margin-right: 0.5rem;
  font-size: 1.2em;
}

.social-links-home {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;
}

.social-links-home a {
  color: #8892b0;
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.social-links-home a:hover {
  color: #64ffda;
}

@media (max-width: 768px) {
  .title-container {
    height: 120px; /* Increase height for mobile */
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 1.4rem;
  }

  .cta-buttons {
    flex-direction: column;
    align-items: center;
  }

  .cta-button {
    width: 100%;
    justify-content: center;
  }
}

/* Add these new styles at the appropriate location in your file */
.profile-image-container {
  width: 200px;
  height: 200px;
  margin: 0 auto 2rem;
  position: relative;
  border-radius: 50%;
  padding: 5px;
  background: linear-gradient(45deg, #64ffda, #0a192f);
  box-shadow: 0 0 20px rgba(100, 255, 218, 0.3);
  cursor: pointer;
}

.profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #0a192f;
  transition: transform 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
}

.profile-image-container::after {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: 50%;
  background: linear-gradient(45deg, #64ffda, #0a192f);
  z-index: -1;
  opacity: 0.5;
  animation: rotate 4s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Update media queries */
@media (max-width: 768px) {
  .profile-image-container {
    width: 150px;
    height: 150px;
  }
  
} 

/* Add these new styles for the slideshow */
.slideshow-dots {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  z-index: 2;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #8892b0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dot.active {
  background-color: #64ffda;
  transform: scale(1.2);
}

.dot:hover {
  background-color: #64ffda;
}

/* Update media queries */
@media (max-width: 768px) {
  .slideshow-dots {
    bottom: -20px;
  }
  
  .dot {
    width: 6px;
    height: 6px;
  }
}
