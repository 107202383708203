.navbar {
  background-color: rgba(10, 25, 47, 0.85);
  backdrop-filter: blur(10px);
  padding: 1rem 2rem;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  color: #64ffda;
  z-index: 1001;
}

.nav-links-desktop {
  display: flex;
  gap: 2rem;
}

.nav-link {
  text-decoration: none;
  color: #8892b0;
  transition: color 0.3s ease;
  font-size: 1rem;
  font-weight: 500;
}

.nav-link:hover {
  color: #64ffda;
}

/* Hamburger Menu Styles */
.menu-icon {
  display: none;
  cursor: pointer;
  z-index: 1001;
}

.hamburger {
  width: 30px;
  height: 20px;
  position: relative;
}

.hamburger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #64ffda;
  border-radius: 9px;
  transition: 0.25s ease-in-out;
}

.hamburger span:nth-child(1) {
  top: 0;
}

.hamburger span:nth-child(2) {
  top: 9px;
}

.hamburger span:nth-child(3) {
  top: 18px;
}

.hamburger.open span:nth-child(1) {
  top: 9px;
  transform: rotate(45deg);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  top: 9px;
  transform: rotate(-45deg);
}

/* Mobile Navigation */
.nav-links-mobile {
  display: none;
}

/* Media Queries */
@media (max-width: 768px) {
  .nav-links-desktop {
    display: none;
  }

  .menu-icon {
    display: block;
  }

  .nav-links-mobile {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 70%;
    background-color: rgba(10, 25, 47, 0.95);
    backdrop-filter: blur(10px);
    padding: 5rem 2rem;
    gap: 2rem;
  }

  .nav-links-mobile .nav-link {
    font-size: 1.2rem;
    padding: 0.5rem 0;
  }
}

/* Adjust main content padding to account for fixed navbar */
.main-content {
  padding-top: 80px;
}
